import type { OrderEntryWidgetModel } from 'src/contracts/workspace';
import type { OrderEntryActions } from 'src/store/actions/widgets/orderEntry';
import type { WidgetTabViewModel } from 'src/store/types';

export const orderEntryReducer = (
    state: WidgetTabViewModel<OrderEntryWidgetModel>,
    action: OrderEntryActions,
): WidgetTabViewModel<OrderEntryWidgetModel> => {
    switch (action.type) {
        case 'updateOrderEntryAccount': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    accountId: action.accountId,
                },
            };
        }
        case 'updateOrderEntrySymbol': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbol: action.symbol,
                    formData: {
                        symbol: action.symbol,
                    },
                },
            };
        }

        default:
            return state;
    }
};
