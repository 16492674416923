import type { AdminAction } from './admin';
import type { AppStateAction } from './app';
import type { AskAlphaAction } from './askAlpha';
import type { AuthAction } from './auth';
import type { ContainerAction } from './container';
import type { FeatureFlagAction } from './featureFlags';
import type { KeyboardShortcutsAction } from './keyboardShortcuts';
import type { LayoutAction } from './layout';
import type { LocatePreferencesAction } from './locates/locatePreferences';
import type { LocatesAction } from './locates/locates';
import type { LocatesSocketAction } from './locates/locatesSocket';
import type { NativeAction } from './native';
import type { OnboardingAction } from './onboarding';
import type { OverlayAction } from './overlay';
import type { TabAction } from './tab';
import type { ThemeAction } from './theme';
import type { UIAction } from './ui';
import type { WidgetAndChannelAction } from './widgetAndChannel';
import type { WidgetOperationActions } from './widgets';
import type { WorkspaceAction } from './workspace';
import type { RouterActions } from 'redux-first-history';
import { CALL_HISTORY_METHOD } from 'redux-first-history';

type AppActionBase =
    | AdminAction
    | AppStateAction
    | AskAlphaAction
    | AuthAction
    | ContainerAction
    | FeatureFlagAction
    | KeyboardShortcutsAction
    | LayoutAction
    | LocatesAction
    | LocatesSocketAction
    | LocatePreferencesAction
    | NativeAction
    | OnboardingAction
    | OverlayAction
    | RouterActions
    | TabAction
    | ThemeAction
    | UIAction
    | WidgetAndChannelAction
    | WorkspaceAction
    | WidgetOperationActions;

export const intentBasedActionTypes: AppActionBase['type'][] = [
    CALL_HISTORY_METHOD,
    'workspace-template-library::open',
    'saveWorkspaceEvent',
    'createBlankWorkspaceEvent',
];

// Actions may have additional metadata attached to them
export interface AppActionMeta {
    meta?: {
        /**
         * The webContentsId of the renderer that dispatched the action
         */
        webContentsId?: number;
    };
    isIntentConfirmed?: boolean;
}

export type AppAction = AppActionBase & AppActionMeta;
export type VerifyIntentAction<T extends AppAction> = { type: 'verifyIntentAction'; originalAction: T };
