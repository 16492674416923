import type { Condition } from '../../scranner/types';
import { QueryClient } from '@tanstack/query-core';
import { fromQuery, handleQueryErrors } from '@thinkalpha/common/util/fromQuery.js';
import { IfThenFieldInView, type ScreenerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { map, shareReplay } from 'rxjs';
import { container } from 'src/StaticContainer';
import { IdeaDTO } from 'src/dtos/Idea';
import { SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import {
    createGuidedConditionModel,
    SCRANNER_CHANGE_EVENT,
    type ScrannerChangeEvent,
    ScrannerPlanDTO,
} from 'src/features/scranner/dtos/ScrannerPlanDTO';
import { ConditionGroupModel } from 'src/features/searchAlpha/dtos/ConditionGroupModel';
import { conditionGroupModelToIfThenGroup } from 'src/features/searchAlpha/dtos/ConditionGroupModel/conditionGroupModelToIfThenGroup';
import { type ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { type SlugMap } from 'src/lib/dictionary/dictionary';
import { FilterStage, SourceStage, type Stage } from 'src/lib/tableStages';
import { getWholeSlugMapQuery } from 'src/queries/slugMap';
import { type ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

const _log = container.get('Logger').getSubLogger({ name: 'feat:screener:ScreenerPlanDTO' });

interface ScreenerPlanDTOInitializer {
    isDirty: boolean;
    conditions: Condition[];
    excludeList: string[];
    includeList: string[];
    mode: 'exclude' | 'include-any' | 'include-all';
}

interface ScreenerPlanDTOState {
    excludeList: string[];
    includeList: string[];
}

const slugMap$ = fromQuery(new QueryClient({}), getWholeSlugMapQuery()).pipe(
    handleQueryErrors(),
    map((x) => x.data),
    shareReplay(1),
);

let slugMap: SlugMap | undefined;
slugMap$.subscribe((x) => {
    slugMap = x;
});

export class ScreenerPlanDTO extends ScrannerPlanDTO {
    get excludeList() {
        return this.#state.excludeList;
    }

    get includeList() {
        return this.#state.includeList;
    }

    constructor(
        state: ScreenerPlanDTOInitializer | undefined,
        queryClient: ThinkAlphaQueryClient,
        formulaService: ReactBindings['FormulaService'],
    ) {
        super(state, queryClient, formulaService);

        this.#queryClient = queryClient;
        this.#formulaService = formulaService;

        this.#state = state ?? {
            excludeList: [],
            includeList: [],
        };
    }

    #formulaService: ReactBindings['FormulaService'];

    override toContract(): ScreenerPlan {
        return {
            ...super.toContract(),
            type: 'screener',
            excludeList: this.#state.excludeList,
            includeList: this.#state.includeList,
        };
    }

    static async fromPlan(
        plan: ScreenerPlan,
        queryClient: ReactBindings['QueryClient'],
        formulaService: ReactBindings['FormulaService'],
    ): Promise<ScreenerPlanDTO> {
        return new ScreenerPlanDTO(
            {
                ...(await ScrannerPlanDTO.fromPlanToInitializer(plan, queryClient, formulaService)),
                excludeList: plan.excludeList,
                includeList: plan.includeList,
            },
            queryClient,
            formulaService,
        );
    }

    setExcludeList(listOrFn: string[] | ((currentList: string[]) => string[])) {
        const excludeList = typeof listOrFn === 'function' ? listOrFn(this.#state.excludeList) : listOrFn;
        const includeList = this.#state.includeList.filter((item) => !excludeList.includes(item));

        this.isDirty = true;

        this.state = {
            ...this.#state,
            excludeList,
            includeList,
        };
    }

    setIncludeList(listOrFn: string[] | ((currentList: string[]) => string[])) {
        const includeList = typeof listOrFn === 'function' ? listOrFn(this.#state.includeList) : listOrFn;
        const excludeList = this.#state.excludeList.filter((item) => !includeList.includes(item));

        this.isDirty = true;
        this.state = {
            ...this.#state,
            includeList,
            excludeList,
        };
    }

    #queryClient: ThinkAlphaQueryClient;

    #state: ScreenerPlanDTOState;

    private set state(newState: ScreenerPlanDTOState) {
        this.#state = newState;
        this.changeTarget.dispatchEvent(new CustomEvent(SCRANNER_CHANGE_EVENT) satisfies ScrannerChangeEvent);
    }

    async toSearchPlan(): Promise<SearchPlanDTO> {
        if (
            !this.conditions.some((x) => x.enabled) &&
            this.#state.includeList.length === 0 &&
            this.#state.excludeList.length === 0
        ) {
            return SearchPlanDTO.fromPlanAndImports(
                {
                    type: 'if-then',
                    root: {
                        type: 'group',
                        name: '',
                        collapsed: false,
                        enabled: true,
                        lines: [
                            {
                                type: 'line',
                                enabled: true,
                                id: v4(),
                                mode: 'formula',
                                formula: this.mode === 'exclude' ? 'true == true' : 'true == false',
                                description: '',
                                fieldInView: IfThenFieldInView.formula,
                            },
                        ],
                        id: v4(),
                        description: '',
                        operator: 'and',
                    },
                    imports: [],
                },
                [],
            );
        }

        if (!slugMap) {
            slugMap = await new Promise<SlugMap>((resolve) => {
                using _sub = slugMap$.subscribe((x) => {
                    if (x) resolve(x);
                });
            });
        }

        const imports: IndicatorImportModel[] = [];

        const symbolIndicator = slugMap['symbol'];
        const symbolIndicatorImportModel = IndicatorImportModel.fromIndicatorAndImports(symbolIndicator, imports);

        let inclusions: ConditionGroupModel | undefined;
        if (this.#state.includeList.length > 0) {
            if (!imports.includes(symbolIndicatorImportModel)) {
                imports.push(symbolIndicatorImportModel);
            }

            inclusions = new ConditionGroupModel({
                id: v4(),
                label: 'Symbol Inclusions',
                children: [],
                booleanOperator: 'or',
                isExpanded: true,
                isSelfEnabled: true,
                parent: false,
            });

            for (const symbol of this.#state.includeList) {
                createGuidedConditionModel(inclusions, symbolIndicatorImportModel.alias, '==', `"${symbol}"`);
            }
        }

        let exclusions: ConditionGroupModel | undefined;
        if (this.#state.excludeList.length > 0) {
            if (!imports.includes(symbolIndicatorImportModel)) {
                imports.push(symbolIndicatorImportModel);
            }

            exclusions = new ConditionGroupModel({
                id: v4(),
                label: 'Symbol Exclusions',
                children: [],
                booleanOperator: 'and',
                isExpanded: true,
                isSelfEnabled: true,
                parent: false,
            });

            for (const symbol of this.#state.excludeList) {
                createGuidedConditionModel(exclusions, symbolIndicatorImportModel.alias, '!=', `"${symbol}"`);
            }
        }

        let conditions: ConditionGroupModel | undefined;
        // eslint-disable-next-line prefer-const -- keeping the same pattern as the inclusion/exclusion lists
        conditions = await this.toConditionGroupModel(imports);

        if (!inclusions && !exclusions && conditions) {
            return SearchPlanDTO.fromPlanAndImports(
                {
                    type: 'if-then',
                    root: conditionGroupModelToIfThenGroup(conditions),
                    imports: imports.values().toArray(),
                },
                imports,
            );
        }

        const rootGroup = new ConditionGroupModel({
            id: v4(),
            label: 'Root',
            children: [],
            booleanOperator: this.mode !== 'exclude' && !exclusions ? 'or' : 'and',
            isExpanded: true,
            isSelfEnabled: true,
            parent: false,
        });

        let middleGroup;
        if (inclusions && exclusions && conditions) {
            middleGroup = new ConditionGroupModel({
                id: v4(),
                label: this.mode === 'exclude' ? 'Exclusions' : 'Inclusions',
                children: [],
                booleanOperator: this.mode === 'exclude' ? 'and' : 'or',
                isExpanded: true,
                isSelfEnabled: true,
                parent: false,
            });

            rootGroup.adoptChild(middleGroup);

            middleGroup.adoptChild(conditions);

            if (this.mode === 'exclude') {
                middleGroup.adoptChild(exclusions);
                rootGroup.adoptChild(inclusions);
            } else {
                middleGroup.adoptChild(inclusions);
                rootGroup.adoptChild(exclusions);
            }
        } else {
            if (conditions) rootGroup.adoptChild(conditions);
            if (inclusions) rootGroup.adoptChild(inclusions);
            if (exclusions) rootGroup.adoptChild(exclusions);
        }

        return SearchPlanDTO.fromPlanAndImports(
            {
                type: 'if-then',
                root: conditionGroupModelToIfThenGroup(rootGroup),
                imports: imports.values().toArray(),
            },
            imports,
        );
    }

    override async toStage(
        inputStage: Stage | null,
        queryClient: ThinkAlphaQueryClient,
        validationMode: boolean,
    ): Promise<FilterStage> {
        if (!validationMode) {
            const result = await this.renderToFormula();

            const { formula, imports } = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula,
                    imports,
                },
                [],
            );
        } else {
            const result = await this.getValidationModeFormulaAndIndicators();

            const [bespokeIndicators, formula] = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula: formula.formula,
                    imports: formula.imports,
                },
                bespokeIndicators,
            );
        }
    }

    /**
     * @deprecated - This is a temporary measure for creating runnable ideas. Long-term we should keep idea states.
     */
    toTempIdea(): IdeaDTO<ScreenerPlanDTO> {
        return IdeaDTO.fromIdeaAndPlan(
            {
                name: 'Scanner Idea',
                isTemplate: false,
                description: null,
                defaultUniverseId: null,
                defaultColumnTemplateId: null,
            },
            this,
        ) as IdeaDTO<ScreenerPlanDTO>;
    }

    async renderToFormula() {
        const searchPlanDTO = await this.toSearchPlan();
        return searchPlanDTO.renderToFormula()!;
    }

    async getValidationModeFormulaAndIndicators() {
        const searchPlanDTO = await this.toSearchPlan();
        return (await searchPlanDTO.getValidationModeFormulaAndIndicators())!;
    }
}
