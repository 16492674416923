import { getTopIdeasRecords, ideaLoader, ideaRecordLoader } from './http/ideas';
import type { FetchQueryOptions, QueryClient, QueryObserverOptions } from '@tanstack/query-core';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { ConcreteIdea, IdeaRecord, TopIdeas } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import { IdeaDTO } from 'src/dtos/Idea';
import { SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import { ScannerPlanDTO } from 'src/features/scanner/dtos/ScannerPlanDTO';
import { ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import { WatchlistPlanDTO } from 'src/features/watchlist/dtos/WatchlistPlanDTO';
import {
    getStrategyRecordsQuery as getIdeaRecordsQueryService,
    getStrategyRecordsCountQuery as getStrategyRecordsCountQueryService,
} from 'src/lib/strategies';
import { ONE_HOUR } from 'src/lib/util/timeConstants';
import { UnreachableCaseError } from 'src/lib/util/unreachableCaseError';
import type { LibraryQueryResult } from 'src/models/LibraryModel';
import type { ReactBindings } from 'src/types/bindings';

/**
 * Get strategy records, which are objects that contain the basic database metadata of the strategy
 */
export const getIdeaRecordByIdQuery = (id: string): UseQueryOptions<IdeaRecord> => {
    return {
        queryKey: ['idea-records', id],
        queryFn: () => ideaRecordLoader.load(id),
    };
};

export const getIdeaByIdQuery = (id: string | null): UseQueryOptions<ConcreteIdea> => {
    return {
        queryKey: ['idea', id],
        queryFn: () => ideaLoader.load(id!),
        enabled: Boolean(id),
    };
};

export const getIdeaDTOByIdQuery = (
    queryClient: ReactBindings['QueryClient'],
    formulaService: ReactBindings['FormulaService'],
    id: string,
): FetchQueryOptions<IdeaDTO> => {
    return {
        queryKey: ['idea', 'model', id],
        queryFn: async () => {
            const idea = await ideaLoader.load(id);

            switch (idea.plan.type) {
                case 'if-then':
                case undefined:
                    return IdeaDTO.fromIdeaAndPlan(
                        idea,
                        SearchPlanDTO.fromPlanAndImports(
                            idea.plan,
                            await Promise.all(
                                idea.plan.imports.map((importRef) =>
                                    IndicatorImportModel.fromIndicatorImportRef(importRef, queryClient),
                                ),
                            ),
                        ),
                    );
                case 'scanner':
                    return IdeaDTO.fromIdeaAndPlan(
                        idea,
                        await ScannerPlanDTO.fromPlan(idea.plan, queryClient, formulaService),
                    );
                case 'screener':
                    return IdeaDTO.fromIdeaAndPlan(
                        idea,
                        await ScreenerPlanDTO.fromPlan(idea.plan, queryClient, formulaService),
                    );
                case 'watchlist':
                    return IdeaDTO.fromIdeaAndPlan(idea, WatchlistPlanDTO.fromPlan(idea.plan));
                case 'formula':
                    throw new Error('Unimplemented conversion from formula plan');
                default:
                    throw new UnreachableCaseError(idea.plan);
            }
        },
        staleTime: 0,
        gcTime: 0,
    };
};

export function getIdeaRecordsQuery(
    resourceQuery: ResourceQuery,
    enabled = true,
): QueryObserverOptions<ResourceQueryResponseWithMeta<IdeaRecord>> {
    return {
        queryKey: ['ideas', 'records', resourceQuery],
        queryFn: () => getIdeaRecordsQueryService(resourceQuery!),
        enabled,
    };
}

export function getIdeaRecordsForLibraryQuery(
    resourceQuery: ResourceQuery,
    enabled = true,
): QueryObserverOptions<LibraryQueryResult<IdeaRecord>> {
    return {
        queryKey: ['ideas', 'library-records', resourceQuery],
        queryFn: async () => {
            const result = await getIdeaRecordsQueryService(resourceQuery!).then(
                (res) => res as ResourceQueryResponseWithMeta<IdeaRecord>,
            );

            return {
                total: result.count,
                rows: result.results,
            };
        },
        enabled,
    };
}

export function removeIdeaFromRecordsQueries(idea: { id: string }, queryClient: QueryClient): void {
    queryClient.setQueriesData(
        { queryKey: ['ideas', 'library-records'] },
        (data: LibraryQueryResult<IdeaRecord>): LibraryQueryResult<IdeaRecord> => {
            const rows = data.rows.filter((row: any) => row.id !== idea.id);
            const total = data.total - 1; // TODO validate we removed a record before updating the total?
            return { total, rows };
        },
    );

    queryClient.setQueriesData(
        { queryKey: ['ideas', 'records'] },
        (data: ResourceQueryResponseWithMeta<IdeaRecord>): ResourceQueryResponseWithMeta<IdeaRecord> => {
            const rows = data.results.filter((row: any) => row.id !== idea.id);
            return { ...data, results: rows };
        },
    );
}

export function getIdeaCountQuery(): QueryObserverOptions<ResourceQueryResponseWithMeta<IdeaRecord>, unknown, number> {
    return {
        queryKey: ['ideas', 'count'],
        queryFn: () => getStrategyRecordsCountQueryService(),
        select: (data) => data.count,
    };
}

export function getTopIdeasForUserQuery(): QueryObserverOptions<TopIdeas> {
    return {
        queryKey: ['ideas', 'top'],
        queryFn: () => getTopIdeasRecords(),
        staleTime: ONE_HOUR,
    };
}

export function removeIdeaFromTopIdeasQuery(idea: { id: string }, queryClient: QueryClient): void {
    queryClient.setQueriesData({ queryKey: ['ideas', 'top'] }, (data: TopIdeas): TopIdeas => {
        const recentlyAccessed = data.recentlyAccessed.filter((x) => x.id !== idea.id);
        const recentlyCreated = data.recentlyCreated.filter((x) => x.id !== idea.id);
        return { recentlyAccessed, recentlyCreated };
    });
}
