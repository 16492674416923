import { ConditionGroupModel } from '../../searchAlpha/dtos/ConditionGroupModel';
import { ConditionModel } from '../../searchAlpha/dtos/ConditionModel';
import type { Condition, QuantitativeCondition } from '../types';
import type { PropIntersection } from '@thinkalpha/common/util/commonProperties.js';
import { type ComparisonOperator, renderValue } from '@thinkalpha/language-services';
import type { ScannerPlan, ScreenerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { IdeaPlanDTO } from 'src/dtos/Idea';
import { IndicatorModel } from 'src/dtos/Indicator';
import { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import { IndicatorPresetModel } from 'src/dtos/IndicatorPreset';
import { getPresetFormulaAndImports } from 'src/lib/parser/getPresetFormulaAndImports';
import { UnreachableCaseError } from 'src/lib/util/unreachableCaseError';
import { type ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

export const SCRANNER_CHANGE_EVENT = 'search-change';
export type ScrannerChangeEvent = CustomEvent<void>;

type ScrannerPlan = PropIntersection<ScreenerPlan, ScannerPlan>;

interface ScrannerPlanDTOInitializer {
    isDirty: boolean;
    conditions: Condition[];
    mode: 'exclude' | 'include-any' | 'include-all';
}

export abstract class ScrannerPlanDTO extends IdeaPlanDTO {
    #conditions: Condition[];
    #isDirty: boolean;
    #mode: 'exclude' | 'include-any' | 'include-all';

    readonly changeTarget = new EventTarget();

    #formulaService: ReactBindings['FormulaService'];
    #queryClient: ReactBindings['QueryClient'];

    constructor(
        state: ScrannerPlanDTOInitializer | undefined,
        queryClient: ReactBindings['QueryClient'],
        formulaService: ReactBindings['FormulaService'],
    ) {
        super();

        this.#queryClient = queryClient;
        this.#formulaService = formulaService;

        this.#conditions = state?.conditions ?? [];
        this.#isDirty = state?.isDirty ?? false;
        this.#mode = state?.mode ?? 'include-all';
    }

    get conditions() {
        return this.#conditions;
    }
    set conditions(conditions) {
        this.#conditions = conditions;
        this.changeTarget.dispatchEvent(new CustomEvent(SCRANNER_CHANGE_EVENT) satisfies ScrannerChangeEvent);
    }

    setConditions(conditionOrFn: Condition[] | ((current: Condition[]) => Condition[])) {
        const updatedConditions = typeof conditionOrFn === 'function' ? conditionOrFn(this.conditions) : conditionOrFn;

        this.#conditions = updatedConditions;
        this.#isDirty = true;

        this.changeTarget.dispatchEvent(new CustomEvent(SCRANNER_CHANGE_EVENT) satisfies ScrannerChangeEvent);
    }

    get isDirty() {
        return this.#isDirty;
    }
    protected set isDirty(isDirty: boolean) {
        this.#isDirty = isDirty;
        this.changeTarget.dispatchEvent(new CustomEvent(SCRANNER_CHANGE_EVENT) satisfies ScrannerChangeEvent);
    }

    get mode() {
        return this.#mode;
    }
    protected set mode(mode) {
        this.#mode = mode;
        this.changeTarget.dispatchEvent(new CustomEvent(SCRANNER_CHANGE_EVENT) satisfies ScrannerChangeEvent);
    }

    setMode(mode: ScrannerPlanDTO['mode']) {
        this.#isDirty = true;
        this.#mode = mode;

        this.changeTarget.dispatchEvent(new CustomEvent(SCRANNER_CHANGE_EVENT) satisfies ScrannerChangeEvent);
    }

    toContract(): ScrannerPlan {
        return {
            mode: this.mode,
            conditions: this.conditions.map((condition) => {
                switch (condition.type) {
                    case 'formula':
                        return {
                            ...condition,
                        };
                    case 'membership':
                    case 'equality':
                    case 'quantitative':
                        return {
                            ...condition,
                            indicator: condition.indicator.toRef(),
                        };
                    default:
                        throw new UnreachableCaseError(condition);
                }
            }),
        };
    }

    protected async toConditionGroupModel(imports: IndicatorImportModel[]): Promise<ConditionGroupModel | undefined> {
        let conditions: ConditionGroupModel | undefined;
        if (this.conditions.some((x) => x.enabled)) {
            conditions = new ConditionGroupModel({
                id: v4(),
                label: 'Conditions Root',
                children: [],
                booleanOperator: this.mode === 'include-any' ? 'or' : 'and',
                isExpanded: true,
                isSelfEnabled: true,
                parent: false,
            });

            for (const condition of this.conditions.filter((x) => x.enabled)) {
                let conditionModel;
                switch (condition.type) {
                    case 'formula': {
                        conditionModel = new ConditionModel({
                            id: v4(),
                            isSelfEnabled: condition.enabled,
                            parent: conditions,
                            conditionState: {
                                type: 'non-guided',
                                state: {
                                    atom: {
                                        status: 'user_code',
                                        code: this.mode === 'exclude' ? `!(${condition.formula})` : condition.formula,
                                    },
                                    naturalLanguage: '',
                                    displayMode: 'formula',
                                },
                            },
                        });
                        break;
                    }

                    case 'membership': {
                        const indicator = condition.indicator.concreteIndicator;

                        const [lhs, addedImports] = await getPresetFormulaAndImports(
                            indicator,
                            imports,
                            condition.selection
                                ? await IndicatorPresetModel.fromSelfAliasIndicatorFormula(
                                      condition.selection,
                                      this.#formulaService,
                                  )
                                : condition.indicator.formulaPresets.find((x) => x.screener)?.match,
                            this.#queryClient,
                        );

                        imports.push(...addedImports);

                        const rhs = (this.mode === 'exclude' ? !condition.invert : !!condition.invert).toString();

                        conditionModel = createGuidedConditionModel(conditions, lhs, '==', rhs);
                        break;
                    }

                    case 'quantitative': {
                        const indicator = condition.indicator.concreteIndicator;
                        const [lhs, addedImports] = await getPresetFormulaAndImports(
                            indicator,
                            imports,
                            condition.selection
                                ? await IndicatorPresetModel.fromSelfAliasIndicatorFormula(
                                      condition.selection,
                                      this.#formulaService,
                                  )
                                : condition.indicator.formulaPresets.find((x) => x.screener)?.match,
                            this.#queryClient,
                        );

                        imports.push(...addedImports);

                        if (condition.operation === 'between') {
                            conditionModel = new ConditionGroupModel({
                                id: v4(),
                                label: 'Quantitative Condition',
                                children: [],
                                booleanOperator: this.mode === 'exclude' ? 'or' : 'and',
                                isExpanded: true,
                                isSelfEnabled: true,
                                parent: conditions,
                            });

                            createGuidedConditionModel(
                                conditionModel,
                                lhs,
                                OperatorMatrix[this.mode][condition.min?.mode ?? 'inclusive']['greaterThan'],
                                condition.min?.value.toString() ?? '0',
                            );

                            createGuidedConditionModel(
                                conditionModel,
                                lhs,
                                OperatorMatrix[this.mode][condition.max?.mode ?? 'inclusive']['lessThan'],
                                condition.max?.value.toString() ?? '0',
                            );
                        } else if (condition.operation === 'greaterThan') {
                            createGuidedConditionModel(
                                conditions,
                                lhs,
                                OperatorMatrix[this.mode][condition.min?.mode ?? 'inclusive'][condition.operation],
                                condition.min?.value.toString() ?? '0',
                            );
                        } else if (condition.operation === 'lessThan') {
                            createGuidedConditionModel(
                                conditions,
                                lhs,
                                OperatorMatrix[this.mode][condition.max?.mode ?? 'inclusive'][condition.operation],
                                condition.max?.value.toString() ?? '0',
                            );
                        }

                        break;
                    }

                    case 'equality': {
                        const indicator = condition.indicator.concreteIndicator;
                        const [lhs, addedImports] = await getPresetFormulaAndImports(
                            indicator,
                            imports,
                            condition.selection
                                ? await IndicatorPresetModel.fromSelfAliasIndicatorFormula(
                                      condition.selection,
                                      this.#formulaService,
                                  )
                                : condition.indicator.formulaPresets.find((x) => x.screener)?.match,
                            this.#queryClient,
                        );

                        imports.push(...addedImports);

                        conditionModel = new ConditionGroupModel({
                            id: v4(),
                            label: `${indicator.name}`,
                            children: [],
                            booleanOperator: this.mode === 'exclude' ? 'and' : 'or',
                            isExpanded: true,
                            isSelfEnabled: true,
                            parent: conditions,
                        });

                        for (const value of condition.values) {
                            createGuidedConditionModel(
                                conditionModel,
                                lhs,
                                this.mode === 'exclude' ? '!=' : '==',
                                renderValue(value),
                            );
                        }

                        break;
                    }
                    default:
                        throw new UnreachableCaseError(condition);
                }
                if (conditionModel) {
                    conditions.adoptChild(conditionModel);
                }
            }
        }

        return conditions;
    }

    static async fromPlanToInitializer(
        plan: ScrannerPlan,
        queryClient: ReactBindings['QueryClient'],
        _formulaService: ReactBindings['FormulaService'],
    ): Promise<ScrannerPlanDTOInitializer> {
        return {
            mode: plan.mode,
            isDirty: false,
            conditions: await Promise.all(
                plan.conditions.map(async (x) => {
                    switch (x.type) {
                        case 'formula':
                            return {
                                ...x,
                                imports: await Promise.all(
                                    x.imports.map((i) => IndicatorImportModel.fromIndicatorImportRef(i, queryClient)),
                                ),
                            };
                        case 'membership':
                        case 'equality':
                            return {
                                ...x,
                                indicator: await IndicatorModel.fromIndicatorRef(x.indicator, queryClient),
                            };
                        case 'quantitative':
                            return {
                                ...x,
                                indicator: await IndicatorModel.fromIndicatorRef(x.indicator, queryClient),
                                operation:
                                    x.min !== null && x.max !== null
                                        ? 'between'
                                        : x.min !== null
                                          ? 'greaterThan'
                                          : 'lessThan',
                            };
                    }
                }),
            ),
        };
    }
}

export function createGuidedConditionModel(
    parent: ConditionGroupModel,
    lhs: string,
    operator: ComparisonOperator,
    rhs: string,
    isSelfEnabled: boolean = true,
) {
    parent.addChild(
        new ConditionModel({
            id: v4(),
            isSelfEnabled,
            parent,
            conditionState: {
                type: 'guided',
                lhs: {
                    naturalLanguage: '',
                    displayMode: 'formula',
                    atom: {
                        status: 'user_code',
                        code: lhs,
                    },
                },
                operator,
                rhs: {
                    naturalLanguage: '',
                    displayMode: 'formula',
                    atom: {
                        status: 'user_code',
                        code: rhs,
                    },
                },
            },
        }),
    );
}

const InverseOperator = { '>': '<=', '>=': '<', '<': '>=', '<=': '>', '==': '!=', '!=': '==' } as const;
const IncludeOperatorMatrix = {
    ['exclusive' satisfies NonNullable<QuantitativeCondition['min']>['mode']]: {
        ['greaterThan' satisfies QuantitativeCondition['operation']]: '>',
        ['lessThan' satisfies QuantitativeCondition['operation']]: '<',
    } as const,
    ['inclusive' satisfies NonNullable<QuantitativeCondition['min']>['mode']]: {
        ['greaterThan' satisfies QuantitativeCondition['operation']]: '>=',
        ['lessThan' satisfies QuantitativeCondition['operation']]: '<=',
    } as const,
} as const;
const ExcludeOperatorMatrix = {
    ['inclusive' satisfies NonNullable<QuantitativeCondition['min']>['mode']]: {
        ['greaterThan' satisfies QuantitativeCondition['operation']]:
            InverseOperator[IncludeOperatorMatrix['inclusive']['greaterThan']],
        ['lessThan' satisfies QuantitativeCondition['operation']]:
            InverseOperator[IncludeOperatorMatrix['inclusive']['lessThan']],
    } as const,
    ['exclusive' satisfies NonNullable<QuantitativeCondition['min']>['mode']]: {
        ['greaterThan' satisfies QuantitativeCondition['operation']]:
            InverseOperator[IncludeOperatorMatrix['exclusive']['greaterThan']],
        ['lessThan' satisfies QuantitativeCondition['operation']]:
            InverseOperator[IncludeOperatorMatrix['exclusive']['lessThan']],
    } as const,
} as const;
const OperatorMatrix = {
    ['include-all' satisfies ScrannerPlan['mode']]: IncludeOperatorMatrix,
    ['include-any' satisfies ScrannerPlan['mode']]: IncludeOperatorMatrix,
    ['exclude' satisfies ScrannerPlan['mode']]: ExcludeOperatorMatrix,
} as const;
