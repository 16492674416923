import { type Idea } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { type ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { type Stage } from 'src/lib/tableStages';

type IdeaDescriptor = Omit<Idea, 'plan'>;

export interface IdeaConstructionOptions<TPlan extends IdeaPlanDTO = IdeaPlanDTO> {
    idea: IdeaDescriptor;
    plan: TPlan;
}

/**
 * Idea DTO
 *
 * This abstracts over the Idea type
 */
export class IdeaDTO<TPlan extends IdeaPlanDTO = IdeaPlanDTO> {
    constructor({ idea, plan }: IdeaConstructionOptions<TPlan>) {
        this.#idea = idea;
        this.#plan = plan;
        this.toStage = plan.toStage.bind(plan);
    }

    static fromIdeaAndPlan(ideaContract: IdeaDescriptor, planDto: IdeaPlanDTO): IdeaDTO {
        return new IdeaDTO({
            idea: ideaContract,
            plan: planDto,
        });
    }

    #idea: IdeaDescriptor;

    get idea(): IdeaDescriptor {
        return this.#idea;
    }

    get name(): string | null {
        return this.#idea.name;
    }

    #plan: TPlan;

    get plan() {
        return this.#plan;
    }

    readonly toStage: TPlan['toStage'];
}

export abstract class IdeaPlanDTO {
    abstract toStage(
        inputStage: Stage | null,
        queryClient: ThinkAlphaQueryClient,
        validationMode: boolean,
    ): Promise<Stage | null>;
}
