import { type ScannerActions } from 'src/store/actions/widgets/scanner';
import type { ScannerWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const scannerReducer = (
    state: WidgetTabViewModel<ScannerWidgetViewModel>,
    action: ScannerActions,
): WidgetTabViewModel<ScannerWidgetViewModel> => {
    switch (action.type) {
        case 'setScannerDisplayingResults': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    displayingResults: action.isShowing,
                },
            };
        }

        case 'setScannerDisplayingBuilder': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    displayingForm: action.isShowing,
                },
            };
        }

        case 'storeScannerSplitPercentage': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    splitPercentage: action.splitPercentage,
                },
            };
        }

        case 'storeScannerUniverseId': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    universeId: action.universeId,
                },
            };
        }

        default:
            return state;
    }
};
