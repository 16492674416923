import z from 'zod';

export const TopItemSchema = z.object({
    type: z.enum(['if-then', 'screener', 'scanner', 'formula', 'watchlist', 'universe']),
    id: z.string(),
});

export type TopItem = z.infer<typeof TopItemSchema>;

export const TopItemsSchema = z.object({
    recentlyAccessed: z.array(TopItemSchema),
    recentlyCreated: z.array(TopItemSchema),
});

export type LocalTopItems = z.infer<typeof TopItemsSchema>;

export interface TopItemsService {
    addRecentlyAccessed(newItem: TopItem): void;
    addRecentlyCreated(newItem: TopItem): void;
    delete(id: string): void;
    getItems(opts?: { types?: TopItem['type'][]; limit?: number }): LocalTopItems;
}
