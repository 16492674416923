import { container } from 'src/StaticContainer';

export class UnreachableCaseError extends Error {
    constructor(value: never) {
        super(`Unreachable case: ${value}`);
    }
}

const log = container.get('Logger').getSubLogger({ module: 'UnreachableCaseError' });
export function assertNever<T>(x: never, returnValue: T): T {
    log.warn('Unreachable case', { error: new UnreachableCaseError(x), returnValue }); // new-ing an error gets us a stack trace here
    return returnValue;
}
