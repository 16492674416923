import { doesAccessGrantAllowRequest } from '@thinkalpha/common/util/permissions.js';
import { ConcreteIdea, Idea, WatchlistPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { union } from 'es-toolkit';
import { IdeaDTO } from 'src/dtos/Idea';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { WATCHLIST_CHANGE_EVENT, WatchlistPlanDTO } from 'src/features/watchlist/dtos/WatchlistPlanDTO';
import type { WatchListWidgetModel } from 'src/models/WatchListWidgetModel';
/* eslint-disable-next-line no-restricted-imports */
import { createIdea, updateIdea } from 'src/queries/http/ideas';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import { setWatchlistIdea } from 'src/store/actions/widgets/watchlist';
import { WatchlistWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class WatchListWidgetModelImpl extends ReactiveInjectable implements WatchListWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#plan = WatchListWidgetModelImpl.createEmptyWatchlistPlan();
        this.#bindEventListeners();
    }

    static createEmptyWatchlistPlan(): WatchlistPlanDTO {
        return new WatchlistPlanDTO({
            isDirty: false,
            includeList: [],
        });
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
        this.plan = WatchlistPlanDTO.fromPlan(this.widget.idea.plan);
        this.#bindEventListeners();
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    #plan: WatchlistPlanDTO;
    @reacts private set plan(plan) {
        this.#plan = plan;
        this.#bindEventListeners();
    }
    get plan() {
        return this.#plan;
    }

    get idea() {
        return this.widget.idea;
    }

    #onWatchlistChange() {
        this.rerender();
    }

    #bindEventListeners() {
        const listener = this.#onWatchlistChange.bind(this);
        this.#plan.changeTarget.addEventListener(WATCHLIST_CHANGE_EVENT, listener);
        this.disposableStack.defer(() => {
            this.#plan.changeTarget.removeEventListener(WATCHLIST_CHANGE_EVENT, listener);
        });
        this.#plan.changeTarget.dispatchEvent(new Event(WATCHLIST_CHANGE_EVENT));
    }

    addSymbol(symbol: string) {
        const userSubmittedSymbols = symbol
            .toUpperCase()
            .replace(/[\r\n ]/g, ',')
            .split(',')
            .map((x) => x.trim())
            .filter(Boolean);

        this.plan.setIncludeList((includeList) => {
            const currentInclusionList = includeList.map((x) => x.toUpperCase()) ?? [];
            return union(currentInclusionList, userSubmittedSymbols);
        });
    }

    get canSaveIdea() {
        if (!this.#plan.isDirty) {
            return false;
        }

        if ('id' in this.idea && 'permissions' in this.idea) {
            return doesAccessGrantAllowRequest(
                (this.idea as ConcreteIdea).permissions.effectiveDirectAccess || undefined,
                'writer',
            );
        }

        return false;
    }

    removeSymbol(symbol: string) {
        this.plan.setIncludeList((includeList) => {
            const currentInclusionList = includeList.map((x) => x.toUpperCase()) ?? [];
            return currentInclusionList.filter((x) => x !== symbol.toUpperCase());
        });
    }

    #getIdeaToSave(): Idea {
        return {
            ...this.idea,
            plan: this.plan.toContract(),
        };
    }

    async saveIdea() {
        const canPut = (idea: Idea): idea is ConcreteIdea => {
            return Boolean(this.idea.id);
        };

        const ideaToSave = this.#getIdeaToSave();

        if (!canPut(ideaToSave)) {
            throw new Error('Cannot save Watchlist without an id');
        }

        const newIdea = (await updateIdea(ideaToSave)) as ConcreteIdea & {
            plan: WatchlistPlan;
        };

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(newIdea, WatchlistPlanDTO.fromPlan(newIdea.plan)) as IdeaDTO<WatchlistPlanDTO>,
        );

        return newIdea;
    }

    async saveIdeaAs(name: string) {
        const ideaToSave = {
            ...this.#getIdeaToSave(),
            name: name,
        };

        if (!ideaToSave.name) {
            throw new Error('Cannot save Watchlist without a name');
        }

        const newIdea = (await createIdea(ideaToSave)) as ConcreteIdea & {
            plan: WatchlistPlan;
        };

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(newIdea, WatchlistPlanDTO.fromPlan(newIdea.plan)) as IdeaDTO<WatchlistPlanDTO>,
        );

        return newIdea;
    }

    get widget() {
        return this.widgetData.widget as WatchlistWidgetViewModel;
    }

    async useNewIdea() {
        const idea = IdeaDTO.fromIdeaAndPlan(
            {
                name: 'Unnamed Watchlist',
                description: 'Watchlist',
                isTemplate: false,
                defaultUniverseId: this.widget.universeId,
                defaultColumnTemplateId: null,
            },
            WatchListWidgetModelImpl.createEmptyWatchlistPlan(),
        );

        await this.useIdea(idea as IdeaDTO<WatchlistPlanDTO>);
    }

    async useIdea(idea: IdeaDTO<WatchlistPlanDTO>) {
        this.store.dispatch(
            setWatchlistIdea(this.widgetData.tabId, {
                ...idea.idea,
                plan: idea.plan.toContract(),
            }),
        );

        this.plan = idea.plan;
        this.#bindEventListeners();
    }
}
