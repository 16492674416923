import type { Idea } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';

export type UserDoubleClickedSymbolFromTableAction = {
    type: 'userDoubleClickedSymbolFromTable';
    symbol: string;
    tabId: string;
};

export const userDoubleClickedSymbolFromTable = (
    tabId: string,
    symbol: string,
): UserDoubleClickedSymbolFromTableAction => ({
    symbol,
    tabId,
    type: 'userDoubleClickedSymbolFromTable',
});

export type SetResultsIdeaAction = {
    type: 'setResultsIdea';
    tabId: string;
    idea: Idea;
};

export const setResultsIdea = (tabId: string, idea: Idea): SetResultsIdeaAction => ({
    type: 'setResultsIdea',
    tabId,
    idea,
});

export type ResultsWidgetActions = UserDoubleClickedSymbolFromTableAction | SetResultsIdeaAction;
