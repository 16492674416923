import { REMOTE_SLICE_STORE_ADDRESS } from '../lib/createRemoteSlice';
import { overlaySlices } from '../overlays';
import { app } from './app';
import { askAlpha } from './askAlpha';
import { auth } from './auth';
import { container } from './container';
import { featureFlags } from './featureFlags';
import { layout } from './layout';
import { locates } from './locates';
import { native } from './native';
import { onboarding } from './onboarding';
import { overlay } from './overlay';
import { tab } from './tab';
import { theme } from './theme';
import { ui } from './ui';
import { workspace } from './workspace';
import type { CombinedState, StateFromReducersMapObject } from 'redux';
import { combineReducers } from 'redux';
import type { IHistoryContext } from 'redux-first-history';

const reducers = {
    app,
    askAlpha,
    auth,
    container,
    featureFlags,
    layout,
    locates,
    native,
    onboarding,
    /** @deprecated */
    overlay,
    tab,
    theme,
    ui,
    workspace,
    [REMOTE_SLICE_STORE_ADDRESS]: combineReducers({
        ...Object.fromEntries(overlaySlices.map((slice) => [slice.name, slice.reducer])),
    }),
};

export type ReducersType = CombinedState<
    StateFromReducersMapObject<typeof reducers & { router: IHistoryContext['routerReducer'] }>
>;

export const createRootReducer = ({ routerReducer }: { routerReducer: IHistoryContext['routerReducer'] }) => {
    return combineReducers({
        ...reducers,
        router: routerReducer,
    });
};
