import { oAuth2Module } from './bootstrap/iocModules/oAuth2Module';
import { AskAlphaListWidgetModelImpl } from './models/AskAlphaListWidgetModel/impl';
import { LibraryModelImpl } from './models/LibraryModel/impl';
import { OrderEntryWidgetModelImpl } from './models/OrderEntryWidgetModel/impl';
import { type QueryObserverModel } from './models/QueryObserverModel';
import { QueryObserverModelImpl } from './models/QueryObserverModel/impl';
import { ResultsAreaModelImpl } from './models/ResultsAreaModel/impl';
import { TimeSeriesWidgetModelImpl } from './models/TimeSeriesWidgetModel/impl';
import { TopItemsServiceImpl } from './services/TopItemsService/impl';
import { type QueryObserverOptions } from '@tanstack/query-core';
import { type TypedContainer } from '@thinkalpha/common/ioc/TypedContainer.js';
import { container as staticContainer } from 'src/StaticContainer';
import { CodeEditorFeatureModule } from 'src/features/code-editor/module';
import { NLPEditorFeatureModule } from 'src/features/nlp/module';
import { client } from 'src/lib/table';
import { AccountsWidgetModelImpl } from 'src/models/AccountsWidgetModel/impl';
import { AggregatedPositionsWidgetModelImpl } from 'src/models/AggregatedPositionsWidgetModel/impl';
import { AlphaLensWidgetModelImpl } from 'src/models/AlphaLensWidgetModel/impl';
import { BlotterWidgetModelImpl } from 'src/models/BlotterWidgetModel/impl';
import { CodeDocumentModelImpl } from 'src/models/CodeDocumentModel/impl';
import { CurrentUserModelImpl } from 'src/models/CurrentUserModel/impl';
import { EventsWidgetModelImpl } from 'src/models/EventsWidgetModel/impl';
import { ExecutionsWidgetModelImpl } from 'src/models/ExecutionsWidgetModel/impl';
import { IfThenBuilderWidgetModelImpl } from 'src/models/IfThenBuilderWidgetModel/impl';
import { ImportsManagerModelImpl } from 'src/models/ImportsManagerModel/impl';
import { LocatesWidgetModelImpl } from 'src/models/LocatesWidgetModel/impl';
import { MessagesWidgetModelImpl } from 'src/models/MessagesWidgetModel/impl';
import { NewsWidgetModelImpl } from 'src/models/NewsWidgetModel/impl';
import { OrdersWidgetModelImpl } from 'src/models/OrdersWidgetModel/impl';
import { PaginationDataModelImpl } from 'src/models/PaginationDataModel/impl';
import { PositionsWidgetModelImpl } from 'src/models/PositionsWidgetModel/impl';
import { ScannerWidgetModelImpl } from 'src/models/ScannerWidgetModel/impl';
import { ScreenerWidgetModelImpl } from 'src/models/ScreenerWidgetModel/impl';
import { SearchAlphaWidgetModelImpl } from 'src/models/SearchAlphaWidgetModel/impl';
import { SimpleOrderEntryWidgetModelImpl } from 'src/models/SimpleOrderEntryWidgetModel/impl';
import { TableModelImpl } from 'src/models/TableModel/impl';
import { TableWidgetModelImpl } from 'src/models/TableWidgetModel/impl';
import { WatchListWidgetModelImpl } from 'src/models/WatchListWidgetModel/impl';
import { WidgetDataModelImpl } from 'src/models/WidgetDataModel/impl';
import { WorkspaceWidgetDataModelImpl } from 'src/models/WorkspaceWidgetDataModel/impl';
import { ConfigServiceImpl } from 'src/services/ConfigService/impl';
import { FormulaServiceImpl } from 'src/services/FormulaService/impl';
import { LoginScreenServiceImpl } from 'src/services/LoginScreenService/impl';
import { SecurityMasterServiceImpl } from 'src/services/SecurityMasterService/impl';
import { SyncInternalConfigurationsServiceImpl } from 'src/services/SyncInternalConfigurationsService/impl';
import { SyncToExternalsServiceImpl } from 'src/services/SyncToExternalsService/impl';
import { WorkspaceServiceImpl } from 'src/services/WorkspaceService/impl';
import type { ReactBindings } from 'src/types/bindings';

export type ReactContainer = TypedContainer<ReactBindings>;

/**
 * Creates the inversify container used by the client and binds all the services.
 *
 * Note that most services you bind here also need to be bound in the electron main container.
 * @see /projects/web/main/ioc/initMainContainer.ts
 */
export const initReactContainer = (): ReactContainer => {
    const container = staticContainer.createChild<ReactBindings>();

    /*
     * Modules and values
     */
    container.bind('ProxyClient').toConstantValue(client);
    container.bind('StoreProvider').toProvider<ReactBindings['Store']>((context) => () => {
        return Promise.resolve((context.container as any as ReactContainer).get('Store'));
    });
    container.load(CodeEditorFeatureModule);
    container.load(NLPEditorFeatureModule);
    container.load(oAuth2Module);
    container
        .bind('QueryObserverModelProvider')
        .toDynamicValue((context) => {
            return function initQueryObserverModel<T>(queryOptions: QueryObserverOptions<T>) {
                const model = context.container.get('QueryObserverModel');
                model.init(queryOptions);

                return model as QueryObserverModel<T>;
            };
        })
        .inSingletonScope();

    /*
     * Services
     */
    container.bind('ConfigService').to(ConfigServiceImpl).inSingletonScope();
    container.bind('FormulaService').to(FormulaServiceImpl).inSingletonScope();
    container.bind('LoginScreenService').to(LoginScreenServiceImpl).inSingletonScope();
    container.bind('SecurityMasterService').to(SecurityMasterServiceImpl).inSingletonScope();
    container.bind('SyncInternalConfigurationsService').to(SyncInternalConfigurationsServiceImpl).inSingletonScope();
    container.bind('SyncToExternalsService').to(SyncToExternalsServiceImpl).inSingletonScope();
    container.bind('TopItemsService').to(TopItemsServiceImpl).inSingletonScope();
    container.bind('WorkspaceService').to(WorkspaceServiceImpl).inSingletonScope();

    /*
     * Models
     */
    container.bind('AccountsWidgetModel').to(AccountsWidgetModelImpl);
    container.bind('AggregatedPositionsWidgetModel').to(AggregatedPositionsWidgetModelImpl);
    container.bind('AlphaLensWidgetModel').to(AlphaLensWidgetModelImpl);
    container.bind('AskAlphaListWidgetModel').to(AskAlphaListWidgetModelImpl);
    container.bind('BlotterWidgetModel').to(BlotterWidgetModelImpl);
    container.bind('CodeDocumentModel').to(CodeDocumentModelImpl);
    container.bind('CurrentUserModel').to(CurrentUserModelImpl).inSingletonScope();
    container.bind('EventsWidgetModel').to(EventsWidgetModelImpl);
    container.bind('ExecutionsWidgetModel').to(ExecutionsWidgetModelImpl);
    container.bind('IfThenBuilderWidgetModel').to(IfThenBuilderWidgetModelImpl);
    container.bind('ImportsManagerModel').to(ImportsManagerModelImpl);
    container.bind('LibraryModel').to(LibraryModelImpl);
    container.bind('LocatesWidgetModel').to(LocatesWidgetModelImpl);
    container.bind('MessagesWidgetModel').to(MessagesWidgetModelImpl);
    container.bind('NewsWidgetModel').to(NewsWidgetModelImpl);
    container.bind('OrderEntryWidgetModel').to(OrderEntryWidgetModelImpl);
    container.bind('OrdersWidgetModel').to(OrdersWidgetModelImpl);
    container.bind('PaginationDataModel').to(PaginationDataModelImpl);
    container.bind('PositionsWidgetModel').to(PositionsWidgetModelImpl);
    container.bind('QueryObserverModel').to(QueryObserverModelImpl);
    container.bind('ResultsAreaModel').to(ResultsAreaModelImpl);
    container.bind('SearchAlphaWidgetModel').to(SearchAlphaWidgetModelImpl);
    container.bind('ScannerWidgetModel').to(ScannerWidgetModelImpl);
    container.bind('ScreenerWidgetModel').to(ScreenerWidgetModelImpl);
    container.bind('SimpleOrderEntryWidgetModel').to(SimpleOrderEntryWidgetModelImpl);
    container.bind('TableModel').to(TableModelImpl);
    container.bind('TableWidgetModel').to(TableWidgetModelImpl);
    container.bind('TimeSeriesWidgetModel').to(TimeSeriesWidgetModelImpl);
    container.bind('WatchListWidgetModel').to(WatchListWidgetModelImpl);
    container.bind('WidgetDataModel').to(WidgetDataModelImpl);
    container.bind('WorkspaceWidgetDataModel').to(WorkspaceWidgetDataModelImpl);

    return container;
};
