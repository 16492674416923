export type UserSetNewsStrategyAction = {
    type: 'userSetNewsStrategy';
    idea: string | null;
    tabId: string;
};

export const userSetNewsStrategy = (tabId: string, idea: string | null): UserSetNewsStrategyAction => ({
    idea,
    tabId,
    type: 'userSetNewsStrategy',
});

export type UserSetNewsUniverseAction = {
    type: 'userSetNewsUniverse';
    tabId: string;
    universeId: string | null;
};

export const userSetNewsUniverse = (tabId: string, universeId: string | null): UserSetNewsUniverseAction => ({
    type: 'userSetNewsUniverse',
    universeId,
    tabId,
});

export interface UserSetNewsSymbolFilter {
    type: 'userSetNewsSymbolFilter';
    symbolFilter: string | null;
    tabId: string;
}

export const userSetNewsSymbolFilter = (tabId: string, symbolFilter: string | null): UserSetNewsSymbolFilter => ({
    type: 'userSetNewsSymbolFilter',
    symbolFilter,
    tabId,
});

export type NewsWidgetActions = UserSetNewsStrategyAction | UserSetNewsUniverseAction | UserSetNewsSymbolFilter;
