import type { QueryClient, QueryObserverOptions } from '@tanstack/query-core';
import type { LocalTopItems, TopItemsService } from 'src/services/TopItemsService';

const TOP_MENU_ITEMS_QUERY_KEY = 'top-menu-items';

export const getTopMenuItemsForKeyQuery = (
    topItems: TopItemsService,
    filters?: Parameters<TopItemsService['getItems']>['0'],
): QueryObserverOptions<LocalTopItems> => ({
    queryKey: [TOP_MENU_ITEMS_QUERY_KEY, filters],
    queryFn: () => {
        return topItems.getItems(filters);
    },
});

export const invalidateTopMenuItemsForKey = (queryClient: QueryClient) => {
    queryClient.invalidateQueries({
        queryKey: [TOP_MENU_ITEMS_QUERY_KEY],
        refetchType: 'active',
    });
};
