import { container } from 'src/StaticContainer';
import { inject, injectable } from 'src/features/ioc';
import { PERSISTED_CURRENT_USER } from 'src/features/local-storage';
import { localforage } from 'src/lib/serialization/localForage';
import type { RootState } from 'src/store';
import { getUser } from 'src/store/selectors';
import type { IsomorphicBindings } from 'src/types/bindings';

const log = container.get('Logger').getSubLogger({ name: 'feat:electron-renderer:syncToExternalsService' });

/**
 * Syncs various data from the store to external services
 */
@injectable()
export class SyncToExternalsServiceImpl {
    constructor(@inject('Store') private readonly store: IsomorphicBindings['Store']) {
        this.store
            .subscribeSelector(getUser, { leading: true })
            .handle((userDetails) => this.syncUserDetails(userDetails));
    }

    private syncUserDetails(userDetails: RootState['auth']['user']) {
        if (TRACE) {
            log.trace('Setting user details', { userDetails });
        }

        try {
            localforage.setItem(PERSISTED_CURRENT_USER, userDetails);
            container.getOptional('DatadogService')?.setUserDetails(userDetails);
        } catch (e) {
            log.error('Failed to sync user details', { err: e });
        }
    }
}
