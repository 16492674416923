import type { NewsWidgetActions } from 'src/store/actions/widgets/news';
import type { NewsWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const newsReducer = (
    state: WidgetTabViewModel<NewsWidgetViewModel>,
    action: NewsWidgetActions,
): WidgetTabViewModel<NewsWidgetViewModel> => {
    switch (action.type) {
        case 'userSetNewsStrategy': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    ideas: action.idea === null ? [] : [action.idea],
                },
            };
        }

        case 'userSetNewsUniverse': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    universeId: action.universeId,
                },
            };
        }

        case 'userSetNewsSymbolFilter': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbolFilter: action.symbolFilter,
                },
            };
        }

        default:
            return state;
    }
};
